import { createStore } from 'vuex'
import { getIndexData,getHelpList } from '../api/api'

export default createStore({
  state: {
    navHeaderList: [],
    phoneNumberList: [],
    articleCateList: [],
    navAd: ''
  },
  getters: {
  },
  mutations: {
    NAVLIST(state,[list,ad]){
      state.navHeaderList = list
      state.navAd = ad
    },
    PHONELIST(state,[helpList,phoneList]){
      state.articleCateList = helpList
      state.phoneNumberList = phoneList
    }
  },
  actions: {
    getHeaderList: async (context,status) => {
      let { data } = await getIndexData({
        tag: 'allBanner',
        banner_code: 'index',
        ds: 4
      })
      context.commit('NAVLIST',[data.bannerindex,data.bottomAd])
    },
    getPhoneNumber: async (context,status) => {
      let { data } = await getHelpList({
        tag: 'helpList',
        ds: 4,
        articleId: 32,
        isHome: 1
      })
      context.commit('PHONELIST',[data.articleCate,data.articleCate[3].articleList])
    }
  },
  modules: {
  }
})
