import axios from 'axios'
import router from '@/router'
let baseURL = ''

if(process.env.NODE_ENV === 'development'){
  baseURL = '/api'
}else if(process.env.NODE_ENV === 'production'){
  baseURL = 'https://apiwebmantest.szwxzw.cn'
}

const request = axios.create({
  baseURL,
})

// 请求拦截器
request.interceptors.request.use(function (config) {
  return config
})
// 响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做处理
  // console.log(response)
  return response.data
}, async error => {
  return Promise.reject(error)
})

export default request
